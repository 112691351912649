// Colors
$color-bg-container: #f0f0f0;

$color-bg-dark-container: #222222;

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer.ui.segment {

  &.dark {
    background: lighten( $color-bg-dark-container, 3%) !important;
  }

  &.light {
    background: darken( $color-bg-container, 3% ) !important;
  }
}
