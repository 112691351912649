html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
}

@import url('https://fonts.googleapis.com/css?family=Fira+Code&display=swap');

html {
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
}

body,
p,
a {
  font-family: 'Fira Code', 'Courier New', 'Ubuntu';
}

.ui .ui.header {
  font-family: 'Fira Code', 'Courier New', 'Ubuntu';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
