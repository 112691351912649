.container.page {
  padding: 40px 20px;
}

.container.page .ui.page-title.home.subheader {
  font-size: 3em;
  font-size: clamp(0.5rem, 2.2vw, 27.5px);
  padding-bottom: 15px;
  margin-bottom: 50px;
}

.auto-resizable-iframe iframe {
  height: 36.6vw;
  width: 65vw;
}
