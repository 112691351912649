// Colors
$bg-color: #ccc;
$bg-color-container: #f0f0f0;
$dot-color: #444;

$bg-color-dark: #444;
$bg-color-dark-container: #222222;
$dot-color-dark: #ccc;

// Dimensions
$dot-size: 1px;
$dot-space: 22px;

.site-wrapper {
  min-height: 100vh;
  position: relative;

  &.light {
    background: linear-gradient(
          90deg,
          $bg-color ($dot-space - $dot-size),
          transparent 1%
        )
        center,
      linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
      $dot-color;
    min-height: 100%;
    background-size: $dot-space $dot-space;
  }

  &.dark {
    background: $bg-color-dark;
    min-height: 100%;
  }
}

.container.site {
  min-height: 100%;
  padding: 0px;
  position: relative;
  background: $bg-color-container;
  min-height: 100vh;
  height: 100%;
}

.container.page-content {
  padding-bottom: 12em;
}

.bold {
  font-weight: bold;
}

.divider {
  color: #fff;
}

// Vertical Rhythm
.vr {
  margin-bottom: 50px !important;
}

.vr-half {
  margin-bottom: 25px !important;
}

// Make all button groups stackable
@media only screen and (max-width: 767px) {
  .ui.stackable.buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ui.stackable.buttons > .button {
    width: 100%;
    border-radius: 0.28571429rem;
  }
  .ui.stackable.buttons > .or {
    margin: 0 auto;
  }
}

.ui.buttons.dark .or::before {
  background: #1b1c1d;
  color: $bg-color-container;
}

.dark .ui.header .sub.header {
  color: $bg-color-container;
}
