.ui.dark.cards > .dark.card, .ui.dark.card {
  background: #444444 !important;
  border: none;
  box-shadow: none;

  .description, .header {
    color: #fff !important;
    border: none;
  }
}

.ui.card {

  .icon {
    float: right;
  }
}
